/* global document */

import React from "react";
import ReactDOM from "react-dom";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { FontProvider, StyleProvider, DarkTheme } from "@getdreams/themes";
import configureStore from "./redux/store";
import "./sass/main.scss";

const { store, persistor } = configureStore();

const Root = Loadable({
  loader: () => import("./containers/root"),
  loading: () => null
});

ReactDOM.render(
  <StyleProvider>
    <FontProvider
      baseUrl={"/fonts"}
      families={{
        "lab-grotesque-dreams": ["regular", "medium", "bold", "black"]
      }}
    >
      <DarkTheme>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Root />
          </PersistGate>
        </Provider>
      </DarkTheme>
    </FontProvider>
  </StyleProvider>,
  document.getElementById("app")
);
