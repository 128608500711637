import { CONNECTION_OFFLINE } from "../../constants";

const initialState = {
  connected: CONNECTION_OFFLINE,
  pingSent: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONNECTION_STATUS":
      return { ...state, connected: action.status };
    case "SET_PING_STATUS":
      return { ...state, pingSent: action.status };
    default:
      return state;
  }
};
