const initialState = {
  loading: true,
  hasValidDDM: null,
  hasUnsoldFundShares: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_OFFBOARDING_STATUS":
      return {
        ...state,
        loading: false,
        hasValidDDM: action.result.hasValidDDM,
        hasUnsoldFundShares: action.result.hasUnsoldFundShares,
        hasIncoming: action.result.hasIncoming,
        availableBalance: action.result.availableBalance
      };
    default:
      return state;
  }
};
