const initialState = {
  sessionId: null,
  ecdsa: null,
  ecdh: null,
  serverPublicKeys: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_SESSION_ID":
      return { ...state, sessionId: action.sessionId };
    case "SET_KEYPAIR":
      return {
        ...state,
        ecdsa: action.keys.ecdsa,
        ecdh: action.keys.ecdh
      };
    case "SET_SERVER_PUBLIC_KEY":
      return { ...state, serverPublicKeys: action.keys };
    case "RESET_CREDENTIALS":
      return { ...initialState };
    default:
      return state;
  }
};
