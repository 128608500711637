const initialState = {
  marketId: null,
  token: null,
  loading: false,
  authInitialized: false,
  authStatus: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGIN_LOADING":
      return { ...state, loading: true, error: null };
    case "SET_LOGIN_ERROR":
      return { ...state, loading: false, error: action.error };
    case "SET_LOGIN_INITIALIZED":
      return {
        ...state,
        loading: false,
        marketId: action.marketId,
        token: action.token,
        authInitialized: true,
        authStatus: "pending"
      };
    case "SET_LOGIN_AUTH_STATUS":
      return { ...state, authStatus: action.status };
    case "RESET_LOGIN_STATUS":
      return { ...initialState };
    case "ABORT_LOGIN":
      return { ...state, authInitialized: false };
    default:
      return state;
  }
};
