const initialState = {
  loading: true,
  loggedIn: false,
  balance: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        loading: false,
        loggedIn: action.user.id !== null,
        ...action.user
      };
    case "RESET_CURRENT_USER":
      return { ...initialState, loading: false };
    case "SET_USER_BALANCE":
      return { ...state, balance: action.balance };
    default:
      return state;
  }
};
