import { combineReducers } from "redux";

import credentials from "./credentials";
import status from "./status";
import user from "./user";
import login from "./login";
import offboarding from "./offboarding";

const rootReducer = combineReducers({
  credentials,
  status,
  user,
  login,
  offboarding
});

export default rootReducer;
